import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';

declare var $: any;
declare var swal: any;

@Injectable()
export class UtilsService {
  constructor(private _location: Location) {}
  toastError = (error?: string) => {
    if (!error) {
      error = "Une erreur s'est produite pendant le traitement";
    }
    this.showNotification(error, 'danger');
  };
  toastSuccess = (success?: string) => {
    if (!success) {
      success = 'Traitement réussi avec succès';
    }
    this.showNotification(success, 'success');
  };
  toastWarning(warn: string) {
    this.showNotification(warn, 'warning');
  }
  toastInfo(info: string) {
    this.showNotification(info, 'info');
  }

  confirm(text?: string): Promise<boolean> {
    if (!text) {
      text = 'Voulez-vous vraiment continuer à réaliser cette action?';
    }
    return new Promise((resolve, reject) => {
      swal(
        {
          title: text,
          imageUrl: 'assets/img/icons/ionicons_android-warning.png',
          imageClass: 'contact-form-image pe-7s-culture',
          showCancelButton: true,
          confirmButtonClass: 'btn btn-danger btn-fill btn-round',
          cancelButtonClass: 'btn btn-default btn-fill btn-round',
          confirmButtonText: 'Oui ',
          cancelButtonText: 'Annuler',
          reverseButtons: true,
          buttonsStyling: false,
        },
        isConfirmed => {
          if (isConfirmed) {
            resolve(true);
          } else {
            reject();
          }
        },
      );
    });
  }
  popupSuccess(message) {
    return swal({
      title: 'Succès!',
      text: message,
      type: 'success',
      confirmButtonClass: 'btn btn-success',
      buttonsStyling: false,
    });
  }
  back() {
    return this._location.back();
  }
  showNotification(message, type?, from?, align?) {
    if (!type) {
      type = 'info';
    }
    if (!from) {
      from = 'top';
    }
    if (!align) {
      align = 'right';
    }
    $.notify(
      {
        icon: 'notifications',
        message: message,
      },
      {
        type: type,
        timer: 3000,
        placement: {
          from: from,
          align: align,
        },
      },
    );
  }
}
