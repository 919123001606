import { User } from '../models/user.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/map';
import { AzureService } from './azure.service';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class SessionService {
  public sessionActive: boolean;
  private serverUrl: string = environment.SERVER_URL;
  public user: User;
  public token: string;
  public user_id: string;
  private sessionData = {
    token: undefined,
    tokenExpiration: undefined,
    user: {
      email: undefined,
      id: undefined,
    },
  };

  private notify = new Subject<any>();
  notifyObservable$ = this.notify.asObservable();

  constructor(private _azure: AzureService, private _http: HttpClient) {
    this.getSessionStatus().subscribe();
  }

  get userData() {
    return this.sessionData.user || JSON.parse(localStorage.getItem('sessionData')).user;
  }

  get isAdmin(): boolean {
    return this.userData.isAdmin;
  }

  getSessionStatus(): Observable<boolean> {
    return Observable.create(async observer => {
      try {
        const token: string = localStorage.getItem('token');
        const tokenExpiration: number = localStorage.getItem('tokenExpiration')
          ? parseInt(localStorage.getItem('tokenExpiration'), 10)
          : 0;
        const sessionData: any = JSON.parse(localStorage.getItem('sessionData'));
        if (!sessionData) {
          // clean all, in case of other session
          this.signout(null);
          observer.next(false);
          return false;
        }
        if (!sessionData.userId) {
          // clean all, in case of other session
          this.signout(null);
          observer.next(false);
          return false;
        }
        this.user = sessionData;

        if (sessionData && sessionData.userId) {
          this.user_id = sessionData.userId;
        }

        const now = Date.now();
        if (token && tokenExpiration && tokenExpiration * 1000 > now) {
          try {
            this.initSession();
            observer.next(true);
          } catch (e) {}
        } else {
          try {
            const userData = await this.regenerate();

            observer.next(userData ? true : false);
          } catch (e) {}
        }
      } catch (e) {
        observer.error(e);
      }
    });
  }

  initSession(data?: any, callback?: () => void) {
    this.sessionActive = true;

    if (data) {
      localStorage.setItem('sessionData', JSON.stringify(data));
      this.sessionData = data;
      localStorage.setItem('token', data.token);
      localStorage.setItem('tokenExpiration', data.tokenExpiration);
    } else {
      const rawData = localStorage.getItem('sessionData');
      if (rawData) {
        try {
          // Try to get data from storage
          this.sessionData = JSON.parse(rawData);
        } catch (e) {}
      }
    }

    if (this.sessionData && this.sessionData.user) {
      this.user = new User(this.sessionData.user);

      this._azure.setCurrentUser({
        userId: this.sessionData.user.id,
        mobileServiceAuthenticationToken: this.sessionData.token,
      });

      this.notifyChange(this.sessionData.user);
    }

    if (callback) {
      callback();
    }
  }

  async signin(user: User, callback: (isSuccess: any) => void) {
    try {
      const data: any = user.prepareForSending();
      data.mobile = false;
      const res = await this._azure.api('/login', 'POST', data);

      if (!res.result.isAdmin) {
        callback(false);
        return;
      }
      try {
        const dataAuth = await this.setUserSession(res.result);

        callback(dataAuth.userId);
      } catch (e) {
        callback(false);
      }
    } catch (e) {
      callback(false);
    }
  }

  setUserSession(result): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const dataAuth = result;

      this._azure.setCurrentUser({
        userId: dataAuth.userId,
        mobileServiceAuthenticationToken: dataAuth.token,
      });
      try {
        const users = await this._azure.getTable('user').where({ id: dataAuth.userId }).read();

        if (users && users.length > 0) {
          dataAuth.user = {
            id: users[0].id,
            email: users[0].email,
            isAdmin: users[0].isAdmin,
            firstname: users[0].firstname,
            lastname: users[0].lastname,
          };
          this.initSession(dataAuth, () => {});
          resolve(dataAuth);
        } else {
          reject(null);
        }
      } catch (e) {
        console.log('e', e);
        reject(e);
      }
    });
  }

  async signup(data, callback: (error: any) => void) {
    try {
      const res = await this._azure.api('/register', 'POST', data);
      await this.setUserSession(res.result);
      callback(null);
    } catch (e) {
      callback(e);
    }
  }

  forgotPassword(data, callback: (isSuccess: any) => void) {
    this._azure.api('/forgot', 'POST', data).then(
      res => {
        callback(true);
        return;
      },
      error => {
        console.log('error', error);
        callback(false);
        return;
      },
    );
  }

  signout(callback: () => void) {
    localStorage.clear();

    this.user = undefined;
    this._azure.setCurrentUser(undefined);
    this.notifyChange(false);
    if (callback) {
      callback();
    }
  }

  notifyChange(data: any) {
    this.notify.next(data);
  }

  regenerate(): Promise<any> {
    const token = this.token ? this.token : localStorage.getItem('token');
    return new Promise((resolve, reject) => {
      if (!token) {
        return null;
      } else {
        let headers = new HttpHeaders();
        headers = headers.append('token', token);
        headers = headers.append('zumo-api-version', '2.0.0');
        const options = { headers: headers };
        this._http
          .get(this.serverUrl + '/api/regenerate', options)
          .map(res => res)
          .subscribe(
            async data => {
              const dataAuth = await this.setUserSession(data);

              // this.initSession(data);
              resolve(dataAuth);
            },
            error => {
              reject(null);
            },
          );
      }
    });
  }
}
