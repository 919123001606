import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SessionService } from './session.service';
import { environment } from 'src/environments/environment';
import { UtilsService } from './utils.service';

@Injectable()
export class AuthGuard implements CanActivate {
  roles: Array<string>;
  currentUserType: any;
  constructor(private router: Router, private _sessionService: SessionService, private _utils: UtilsService) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      try {
        this._sessionService.getSessionStatus().subscribe(sessionStatus => {
          if (sessionStatus) {
            resolve(sessionStatus);
          } else {
            // this._utils.toastWarning('Session non autorisé, veuillez vous reconnecter');
            this.router.navigate(['/auth/login']);
            reject(sessionStatus);
          }
        });
      } catch (e) {
        const token = localStorage.getItem('token');
        if (token && token.length) {
          try {
            const data = await this._sessionService.regenerate();
            resolve(true);
          } catch (e) {
            reject(e);
          }
        } else {
          this._utils.toastWarning('Session non autorisé, veuillez vous reconnecter');
          this.router.navigate(['/auth/login']);
          reject(e);
        }
      }
    });
  }
}
